<div class="modal-body">

  <div *ngIf="status === 'success'" class="alert alert-success  mb-3">
    <fa-icon [icon]="faCheckCircle" class="text-success me-2"></fa-icon>
    <strong>Email verification request submitted
      <ng-container *ngIf="email"> to {{email}}</ng-container>
    .
    </strong>
    Check your email inbox for your email verification link.
  </div>

  <div *ngIf="status === 'error'" class="alert alert-danger mb-3">
    <fa-icon [icon]="faCheckCircle" class="text-success me-2"></fa-icon>
    <strong>Email verification request error.</strong>
    Please try again shortly.
  </div>

  <div class="alert alert-info mt-3 mb-5">
    <strong>Please verify your email</strong> by clicking the verification link in the email you received after signing up.
  </div>

  <div class="mt-3">

    <!-- Using a one level up link to ensure we refresh - TODO: look at how to use this: onSameUrlNavigation: 'reload' -->
    <div>If you have already successfully verified in another browser window, you can now
      <a (click)="reloadPage()" class="clickable">browse to your dashboard</a>.
    </div>


    <div class="pt-5">
      Can't find the email?
      <span class="clickable verify-link pt-2" (click)="sendVerificationEmail()">Request a new email verification link.</span>
    </div>
  </div>

</div>
