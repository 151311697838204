import { Inject, Injectable } from '@angular/core';
import { catchError, filter, from, map, mergeMap, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  Auth,
  AuthError,
  createUserWithEmailAndPassword,
  getRedirectResult,
  GoogleAuthProvider,
  idToken,
  SAMLAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithRedirect
} from '@angular/fire/auth';
import { TrpcService } from './trpc.service';
import { UserResponseType } from 'portal-types';
import { TRPCClientError } from '@trpc/client';
import Rollbar from 'rollbar';
import { RollbarService } from './rollbar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyEmailModalComponent } from '../verify-email-modal/verify-email-modal.component';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private fbAuth: Auth,
              private trpc: TrpcService,
              private ngbModal: NgbModal,
              private transloco: TranslocoService,
              @Inject(RollbarService) private rollbar: Rollbar) {

    this.transloco.langChanges$
      .pipe(
        tap(language => this.fbAuth.languageCode = language)
      )
      .subscribe();
  }

  getToken(): Observable<string | null> {
    return idToken(this.fbAuth);
  }

  ssoSignIn(): Observable<UserResponseType> {
    const isAbfasLocation = window.location.pathname.includes('/providers/abfas/');

    return this.maybeHandleSignInWithRedirect()
      .pipe(
        mergeMap(() => this.trpc.client.sso.getToken.query()),
        mergeMap(token => from(signInWithCustomToken(this.fbAuth, token))),
        mergeMap(userCredential => {
          if (!userCredential.user.emailVerified && !isAbfasLocation) {
            this.ngbModal.open(VerifyEmailModalComponent, {
              size: 'lg',
              centered: true,
              beforeDismiss: () => false
            });
            throw new Error(`Email is not verified`);
          }
          return from(this.trpc.client.users.get.query())
            .pipe(
              catchError(e => {
                if (e instanceof TRPCClientError && e.data.httpStatus === 401) {
                  // Firebase sign in is valid but user doesn't exist in platform
                  this.rollbar.error(`User ${userCredential.user.email} has firebase account but failed to find user`);
                  return this.ssoSignOut();
                }

                throw e;
              })
            )
        }),
        tap(() => this.monitorFirebaseAuthState())
      );
  }

  ssoSignOut(): Observable<never> {
    return from(this.trpc.client.sso.signOut.mutate())
      .pipe(
        tap(() => location.reload())
      );
  }

  // If it's sign in with redirect, it never returns
  private maybeHandleSignInWithRedirect(): Observable<unknown> {
    return from(getRedirectResult(this.fbAuth))
      .pipe(
        mergeMap(user => user ? this.signedIn(): of(null))
      );
  }

  signInWithGoogle(): Observable<unknown> {
    // We don't need to call signedIn since this causes redirect
    return from(signInWithRedirect(this.fbAuth, new GoogleAuthProvider()));
  }

  signInWithPassword(email: string, password: string): Observable<unknown> {
    return from(signInWithEmailAndPassword(this.fbAuth, email, password))
      .pipe(
        mergeMap(() => this.signedIn())
      );
  }
  private signedIn(): Observable<never> {
    return idToken(this.fbAuth)
      .pipe(
        take(1),
        mergeMap(token => this.trpc.client.sso.signIn.mutate(token!)),
        tap(() => location.reload())
      );
  }

  signUpWithPassword(organization: string, email: string, password: string) {
    return from(createUserWithEmailAndPassword(this.fbAuth, email, password))
      .pipe(
        // "On successful creation of the user account, this user will also be signed in to your application."
        mergeMap(() => from(sendEmailVerification(
          this.fbAuth.currentUser!,
          {url: `${environment.client}/providers/${organization}/dashboard`,handleCodeInApp: false}))),
        mergeMap(() =>
          idToken(this.fbAuth)
            .pipe(
              mergeMap(token => from(this.trpc.client.users.signUp.mutate({ organization, token: token! }))
            )
          )),
        mergeMap(() => this.signedIn()),
        catchError((err: AuthError) => this.mapSignupErrors(err)),
      );
  }

  signInWithSaml(provider: string) {
    const samlProvider = new SAMLAuthProvider(provider);
    return from(signInWithRedirect(this.fbAuth, samlProvider));
  }

  isLoggedInWithSaml(): boolean {
    let isLoggedIn = false;

    from(getRedirectResult(this.fbAuth))
      .pipe(
        tap(res => console.log(res)),
        mergeMap(res => of(!!res)),
        catchError(err => {
          console.log('err');
          console.log(err);
          return of(false);
        }),
        tap(res => console.log(res)),
        tap(res => isLoggedIn = res)
      )
      .subscribe();

    return isLoggedIn;
  }

  private monitorFirebaseAuthState() {
    // idToken emits when:
    // 1. Pushed logout button
    // 2. refreshing token failed because sso.signOut is called on different device
    // Note 1: not authState but idToken method is used since unlike idToken/user, authState is not invoked when token refreshes
    // Note 2: in order for refresh to happen, you need to actively read user/id token somewhere (we do it in route guard)
    idToken(this.fbAuth)
      .pipe(
        filter(token => !token),
        tap(() => location.reload())
      )
      .subscribe();
  }

  sendEmailVerification(organization: string): Observable<string> {
    return from(sendEmailVerification(
      this.fbAuth.currentUser!,
      {url: `${environment.client}/providers/${organization}/dashboard`,handleCodeInApp: false}))
      .pipe(
        map(() => this.fbAuth.currentUser!.email!)
      );
  }

  triggerPasswordResetEmail(email: string, location: string): Observable<void> {
    return from(
      sendPasswordResetEmail(
        this.fbAuth,
        email,
        {
          url: `${environment.client}/providers/${location}/dashboard`,
          handleCodeInApp: false
        }));
  }

  private mapSignupErrors(err: AuthError): Observable<never> {
    switch (err.code) {
      case 'auth/email-already-in-use':
        throw err;
      /** nbcrna: throw new Error('You cannot set a new password as this email is already in use. Please sign in
       * instead.'); **/
      case 'auth/password-does-not-meet-requirements':
        let passwordErr = 'Password does not meet requirements.';

        const matchRequirements = err.message.match(/^Firebase: Missing password requirements: \[(.*)\]/);
        if (matchRequirements && matchRequirements.length > 1) {
          passwordErr = matchRequirements[1];
        }
        throw new Error(passwordErr);
      default:
        throw new Error((err as AuthError).code.replace(/^auth\//, ''));
    }
  }
}
