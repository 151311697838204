import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { catchError, finalize, mergeMap, tap } from 'rxjs';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { LoadingOverlayService } from '../shared/loading-overlay/loading-overlay.service';
import { ApplicationStateService } from '../services/application-state.service';

@Component({
  selector: 'portal-verify-email-modal',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterLink],
  templateUrl: './verify-email-modal.component.html',
  styleUrls: ['./verify-email-modal.component.scss']
})
export class VerifyEmailModalComponent {
  protected readonly faCheckCircle = faCheckCircle;
  protected status: 'error' | 'success' | undefined;
  protected email: string = '';

  constructor(private authentication: AuthenticationService,
              private overlay: LoadingOverlayService,
              private appState: ApplicationStateService) {
  }

  reloadPage() {
    window.location.reload()
  }

  sendVerificationEmail() {
    this.overlay.show();
    this.appState.select((state) => state.organization)
      .pipe(
        mergeMap((organization) => this.authentication.sendEmailVerification(organization!)),
        tap(email => this.email = email),
        catchError(e => {
          this.status = 'error';
          throw e;
        }),
        finalize(() => this.overlay.hide())
      )
      .subscribe();
  }
}
